<template>
  <div class="d-flex">
    <div class="shrink">
      <v-icon left>{{icon}}</v-icon>
    </div>
    <div class="shrink">
      <p>{{text}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['icon', 'text']
}
</script>